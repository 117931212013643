document.addEventListener('turbo:load', () => {
  setTimeout(() => {
    let buttons = document.querySelectorAll('.btn.btn-secondary, .btn.btn-primary, .btn.btn-default')
    buttons.forEach((btn) => {
      let textContent = btn.textContent
      if(!btn.querySelector('.btn-text')) {
        btn.textContent = ''
        let spanDiv = document.createElement('span')
        spanDiv.classList.add('btn-text')
        spanDiv.textContent = textContent
        btn.appendChild(spanDiv)
      }
    })
  }, 1000)
})
